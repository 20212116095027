import React from 'react';
import { Button } from 'react-bootstrap';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { isLoggedIn } from 'services/auth.api';
import MainLayout from '../layouts/mainLayout';
import str_img_or_1 from '../assets/str_img_or_1.png';
import str_img_or_2 from '../assets/str_img_or_2.png';
import str_img_or_3 from '../assets/str_img_or_3.png';
import { OptimizedImage } from '../components/common/OptimizedImage';
import HowItemCard from '../components/common/InfoItemsCard/InfoItemsCard';

const JoinFurFamily = loadable(() => import('components/JoinFurFamily'));

const onGetStarted = () => {
  navigate(isLoggedIn() ? '/account/dashboard/' : '/getstarted/');
};

const howItems = [
  {
    imgSrc: str_img_or_1,
    imgAlt: 'Recyclable',
    imgTitle: 'Recyclable',
    title: 'Recyclable & Compostable Packaging',
    description:
      'Our goal has always been plastic free pet food. We’ve started with 60% reduced plastic, recyclable and compostable materials... and we will keep getting better.',
  },
  {
    imgSrc: str_img_or_2,
    imgAlt: 'packaging',
    imgTitle: 'packaging',
    title: 'Smart Packaging',
    description:
      'We’ve designed a whole packaging system that minimises all of the packaging materials in getting pet food to you. Not just the bits you see.',
  },
  {
    imgSrc: str_img_or_3,
    imgAlt: 'NZdesigned',
    imgTitle: 'NZdesigned',
    title: 'New Zealand designed and made',
    description:
      'Everything we make is sourced, made and supplied in New Zealand, including our packaging. So we use far less carbon miles, than all the imported brands.',
  },
];
export default () => {
  return (
    <MainLayout withTitle>
      <div className="banner str-banner">
        <OptimizedImage fileName="home_block_1.jpg" />
        <div className="container-fluid">
          <div className="banner-content sustainability-content">
            <div className="petcare-banner-img"></div>
            <h5>Sustainable NZ Made Dog Food</h5>
            <h1>
              Our Planet is fragile,
              <br />
              it needs protecting
            </h1>
          </div>
        </div>
      </div>
      <div className="ingr-col-wrapper no-mgn-bot">
        <div className="container-fluid">
          <div className="teat-bg">
            <div className="def-w-max">
              <div className="ing-hdr-wrap text-center">
                <h2 className="mgn-bot-50">
                  NZ made dog food shouldn’t cost the earth.
                </h2>
                <p className="para-txt mgn-bot-50">
                  Feed My Furbaby is absolutely committed to being the most
                  sustainable pet care company, bar none!
                  <br />
                  We believe that making it easier for us all to be concious pet
                  parents is the best way to reduce our pet’s impact on our
                  planet.
                </p>
                <h4 className="mgn-bot-50">
                  <i>
                    "Our mission is helping humans be the best pet parents we
                    can be. That means taking better care of our pets, our
                    planet and making it so easy you don’t even have to think
                    about it”
                  </i>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="how-wrapper"
        data-title="Buy dog food Online NZ. Dog food subscription delivery - Furbaby"
      >
        <div className="container-fluid">
          <div className="how-hdr">
            <h2 className="mgn-bot-20">
              Tackling Plastic Packaging in Pet Food
            </h2>
          </div>
          <div className="how-card def-w-max pdd-top-40">
            <div className="row justify-content-center">
              {howItems.map((item, index) => (
                <HowItemCard
                  key={index}
                  imgSrc={item.imgSrc}
                  imgAlt={item.imgAlt}
                  imgTitle={item.imgTitle}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="features-wrapper mgn-bot-100">
        <div className="container-fluid">
          <div className="def-w-max">
            <div className="treats-3-bg">
              <div className="section-item def-w-max no-mgn">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="op-img-wrap">
                      <OptimizedImage fileName="str_img_2.png" />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="txt-wrap-sect">
                      <h2 className="mgn-bot-30">It’s our passion</h2>
                      <p className="para-txt mgn-bot-50">
                        You only need to look at our packaging and you can see
                        it’s different to anything else in the pet food
                        market... a lot of love has gone into it. <br />
                        <br />
                        We’re a kiwi fur-family just like you and we look at
                        everything as both pet parents and human parents wanting
                        to leave our kids a better world.
                      </p>
                      <Button className="btn-def" onClick={onGetStarted}>
                        {isLoggedIn() ? 'Dashboard' : 'Get started now'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="how-wrapper ingr-col-wrapper str-col-wrapper mgn-top-0">
        <div className="container-fluid">
          <div className="how-hdr">
            <h2>We're Plastic Neutral!</h2>
          </div>
          <div className="how-card def-w-max">
            <div className="ing-hdr-wrap text-center">
              <div className="op-img-wrap plastic_neutral_img">
                <OptimizedImage fileName="plastic_neutral_bw.png" />
              </div>
              <p className="mgn-top-40">Our product is</p>
              <h2 className="mgn-top-40 plastic_neutral_title">
                Plastic Neutral Certified
              </h2>
              <p className="para-txt mgn-top-40 mgn-bot-60 mgn-bot-60 mgn-bot-60 pdd-lt-50 pdd-rt-50">
                we donate a % of every sale to support the removal of as much
                plastic waste from nature as we use in our packaging through
                rePurpose Global.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="features-wrapper">
        <div className="container-fluid">
          <div className="def-w-max">
            <div className="op-img-wrap plastic_neutral_img">
              <OptimizedImage fileName="re_purpose_img.png" />
            </div>
            <h2 className="mgn-top-50 mgn-bot-60 text-center">How it works</h2>
            <div className="mgn-bot-60  text-center">
              <div className="row">
                <div className="col-12 col-sm-4 text-center">
                  <div className="img-txt-wrapper re_purpose_card">
                    <h3 className="re_purpose_card_title">1. Measure </h3>
                    <p>
                      We donate a percentage of every product purchase to fund
                      the collection of 520 kgs of low-value PP plastic waste
                      from oceans and landfills every year.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-4 text-center">
                  <div className="img-txt-wrapper re_purpose_card">
                    <h3 className="re_purpose_card_title"> 2. Compensate </h3>
                    <p>
                      Low-value plastic is collected, processed, and reused by
                      Saahas Zero Waste - a waste management service in India
                      that employs and empowers waste pickers to tackle plastic
                      waste.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-4 text-center">
                  <div className="img-txt-wrapper re_purpose_card">
                    <h3 className="re_purpose_card_title">3. Reduce</h3>
                    <p>
                      We work with rePurpose Global to reduce the future use of
                      virgin plastics in their products and build a more
                      circular business and delivery model for their company.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="btn-wrapper text-center mgn-bot-100">
        <a href="https://www.business.repurpose.global/feedmyfurbaby" target="_blank">
          <Button className="btn-def mgn-top-30">Find out more</Button>
        </a>
      </div>

      <div className="features-wrapper mgn-bot-100">
        <div className="container-fluid">
          <div className="def-w-max">
            <div className="fs-banner">
              <OptimizedImage fileName="saahas.png" />
            </div>
          </div>
        </div>
      </div>

      <div className="features-wrapper mgn-bot-100">
        <div className="container-fluid">
          <div className="def-w-max">
            <div className="fs-banner">
              <OptimizedImage fileName="sustainability_map.png" />
            </div>
          </div>
        </div>
      </div>

      <div className="features-wrapper mgn-bot-60">
        <div className="container-fluid">
          <div className="def-w-max">
            <h2 className="text-center mgn-bot-40">
              Sustainability Action Plan
            </h2>
            <div className="section-item def-w-max no-mgn">
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="">
                    <OptimizedImage fileName="kate_img.jpeg" />
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <p className="para-txt mgn-bot-50">
                    We are commited to the following sustainbility goals: <br />
                  </p>
                  <br />
                  <ul className="mgn-bot-60">
                    <li>
                      <p className="para-txt">
                        Initiate the move from 60 to 100% zero waste
                      </p>
                    </li>
                    <li>
                      <p className="para-txt">
                        Become a carbon POSITIVE business
                      </p>
                    </li>
                    <li>
                      <p className="para-txt">
                        Educate customers on the importance of sustainable
                        choices
                      </p>
                    </li>
                    <li>
                      <p className="para-txt">
                        Develop targets and continue to improve as a business
                      </p>
                    </li>
                  </ul>
                  <div className="">
                    <OptimizedImage fileName="pet_care_banner_img.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mgn-top-50">
              <p className="para-txt">
                follow this awesome human and her Furbaby to learn how you can
                be more sustainable with everything you do!
              </p>
            </div>
          </div>
        </div>
      </div>
      <JoinFurFamily page_id="sustainability_page_footer" />
    </MainLayout>
  );
};
